<template>
  <Content>
    <template #contentTitle>{{ $t("menu.print_waybill_list") }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]"
             type="flex"
             justify="space-between"
             align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input v-model:value="searchState.searchKey"
                       :placeholder="`${$t('warehouse.customer_number')}/${$t(
                  'warehouse.system_number'
                )}/${$t('warehouse.tracking_number_1')}`"
                       :title="`${$t('warehouse.customer_number')}/${$t(
                  'warehouse.system_number'
                )}/${$t('warehouse.tracking_number_1')}`"
                       allowClear
                       style="width: 200px" />
            </a-col>
            <a-col>
              <a-select v-model:value="searchState.destinationType"
                        :placeholder="$t('warehouse.destination_type')"
                        allowClear
                        style="width: 200px">
                <a-select-option v-for="(item, index) in destinationTypeList"
                                 :key="index"
                                 :value="item"
                                 :title="$t($enumLangkey('destinationType', item))">{{
                    $t($enumLangkey("destinationType", item))
                  }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="searchState.fromCountryId"
                        :placeholder="$t('warehouse.originating_country')"
                         show-search
                         allowClear
                          optionFilterProp="search-key"
                        style="width: 200px">
                <a-select-option v-for="(item, index) in fromCountryList"
                                 :key="index"
                                 :search-key="item.countryCnName + item.countryEnName+item.ioS2"
                                 :value="item.countryId"
                                 :title="getLanguageName(item.countryCnName, item.countryEnName)+'('+item.ioS2+')'">{{
                    getLanguageName(item.countryCnName, item.countryEnName)
                  }}({{item.ioS2}})</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-input-group compact>
                <a-select style="min-width: 150px"
                          v-model:value="searchState.toCountryId"
                          :placeholder="$t('common.destination_country')"
                          @change="handleToCountryChange"
                          show-search
                          optionFilterProp="search-key"
                          allowClear>
                  <a-select-option v-for="(item, index) in toCountryList"
                                   :key="index"
                                   :search-key="item.cnName + item.enName+item.ioS2"
                                   :title="getLanguageName(item.cnName, item.enName)+'('+item.ioS2+')'"
                                   :value="item.id">{{
                      getLanguageName(item.cnName, item.enName)
                    }}({{item.ioS2}})</a-select-option>
                </a-select>
                <a-select style="min-width: 150px"
                          v-model:value="searchState.toCityId"
                          :placeholder="$t('logistics.city_search')"
                          :filter-option="false"
                          show-search
                          optionFilterProp="search-key"
                          @search="getCityList"
                          :disabled="!searchState.toCountryId"
                          allowClear>
                  <a-select-option v-for="(item, index) in toCityList"
                                   :key="index"
                                   :value="item.id"
                                   :search-key="item.cnName + item.enName+item.abbrCode"
                                   :title="getLanguageName(item.cnName, item.enName)+'('+getLanguageName(item.provinceCnName,item.provinceEnName)+')'">
                    <template v-if="toCityListLoading"
                              #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    {{ getLanguageName(item.cnName, item.enName) }}({{item.provinceAbbrCode}})
                  </a-select-option>
                </a-select>
              </a-input-group>
            </a-col>
            <a-col>
              <a-select v-model:value="searchState.waybillOperationStatus"
                        :placeholder="$t('warehouse.operating_state')"
                        allowClear
                        style="width: 200px">
                <a-select-option v-for="(item, index) in waybillOperationStatusList"
                                 :key="index"
                                 :value="item"
                                 :title="$t($enumLangkey('waybillOperationStatus', item))">{{
                    $t($enumLangkey("waybillOperationStatus", item))
                  }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <span class="mr-2">{{ $t('warehouse.creation_time') }}:</span>
              <a-range-picker style="width: 250px"
                              @change="handleDateChange" />
            </a-col>
            <a-col>
              <a-space>
                <a-button type="primary"
                          :loading="tableData.loading"
                          @click="handleSearch">{{
                $t("common.query")
              }}</a-button>
                <a-button type="ghost"
                          :loading="tableData.loading"
                          @click="handleDownloadExcel">{{
                  $t("common.export")
                }}</a-button>
              </a-space>

            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <router-link :to="{ name: 'print_waybill_create' }">
            <a-button type="primary"
                      ghost>{{ $t("common.create") }}</a-button>
          </router-link>
        </a-col>
      </a-row>
      <a-table class="mt-3"
               :columns="columns"
               size="small"
               :data-source="tableData.tableList"
               :pagination="false"
               :scroll="{ x: 500, y: wrap.contentHeight - 170 }"
               :rowKey="
          (record, index) => {
            return index;
          }
        "
               :loading="tableData.loading">
        <template #orderNo>
          <div>{{ $t("warehouse.customer_number") }}</div>
          <div>{{ $t("warehouse.system_number") }}</div>
        </template>
        <template #orderNoCustom="{ record }">
          <div>{{ record.customerNo }}</div>
          <div>{{ record.planNo }}</div>
        </template>
        <template #transportRoutes="{ record }">
          <div>
            {{ $t($enumLangkey("transportRoutes", record.transportRoutes)) }}
          </div>
        </template>
        <template #destinationType="{ record }">
          <div>
            {{ $t($enumLangkey("destinationType", record.destinationType)) }}
          </div>
        </template>
        <template #from="{ record }">
          <div>
            {{
              getLanguageName(
                record.from.countryCnName,
                record.from.countryEnName
              )
            }}
          </div>
        </template>
        <template #toTitle>
          <div>{{ $t('common.destination_country') }}</div>
          <div>{{ $t('logistics.city') }}</div>
        </template>
        <template #to="{ record }">
          <div>{{ getLanguageName(record.to.countryCnName, record.to.countryEnName) }}</div>
          <div>{{ getLanguageName(record.to.cityCnName, record.to.cityEnName) }}</div>
        </template>
        <template #weightTitle>
          <div>{{ $t('warehouse.actual_weight') }}</div>
          <div>{{ $t('logistics.chargeable_weight') }}</div>
        </template>
        <template #totalWeight="{ record }">
          <div>{{ handleGToKg(record.totalWeight) }}kg</div>
          <div>{{ handleGToKg(record.totalBillingWeight) }}kg</div>
        </template>
        <template #totalVolume="{ record }">
          {{ handleCmCubicToM(record.totalVolume) }}m<sup>3</sup>
        </template>
        <template #totalPrice="{ record }">
          <span v-if="record.totalPrice">{{ $filters.formatCurrencyAmount( record.symbol, record.totalPrice, false) }}</span>
          <span v-else>-</span>
        </template>

        <template #channelTitle>
          <div>{{ $t("warehouse.channel_code") }}</div>
          <div>{{ $t("warehouse.channel_name") }}</div>
        </template>
        <template #supplierLogistic="{ record }">
          <template v-if="record.supplierLogisticCode && record.supplierLogisticName">
            <div>{{ record.supplierLogisticCode }}</div>
            <div>{{ record.supplierLogisticName }}</div>
          </template>
          <span v-else>-</span>
        </template>
        <template #transferOrderNo="{ record }">
          <span v-if="record.transferOrderNo">{{ record.transferOrderNo }}</span>
          <span v-else>-</span>
        </template>
        <template #waybillOperationStatus="{ record }">
          <span v-if="record.waybillOperationStatus">{{
            $t(
              $enumLangkey(
                "waybillOperationStatus",
                record.waybillOperationStatus
              )
            )
          }}</span>
          <span v-else>-</span>
        </template>
        <template #track="{ record }">
          <a-row :gutter="8">
            <a-col><small style="color: #aaa;">{{ $filters.utcToCurrentTime(record.track?.trackTime) }}</small></a-col>
            <a-col><small style="color: #aaa;">{{ record.track?.trackRemark }}</small></a-col>
          </a-row>
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #operation="{ record }">
          <a-dropdown-button @click="handleNavigate(record)">
            {{ 
            (
            record.planStatus === 1 
           &&
            !(record.waybillOperationStatus==waybillOperationStatusEnum.cancelled)
            &&
            !(record.waybillOperationStatus==waybillOperationStatusEnum.managerCancelled)
           )
           ? $t("common.edit") : $t("common.details") }}
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item v-if="record.canPrintSupplierOrder"
                             :key="1"
                             :record="record">
                  {{ $t("warehouse.print_waybill") }}
                </a-menu-item>
                <a-menu-item v-if="record.canCanelPlan"
                             :key="2"
                             :record="record">
                  {{ $t("warehouse.cancel_waybill") }}
                </a-menu-item>
                <a-menu-item :key="3"
                             :record="record">
                  {{ $t("warehouse.check_the_track") }}
                </a-menu-item>
                <a-menu-item :key="4"
                             :record="record">
                  {{ $t("common.copy") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>

       <CTrackInfoModal
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
        :loading="trackModal.loading"
      >
        <template v-slot:numberName>
          {{ $t("logistics.waybill_number") }}
        </template>
         <template v-slot:no>
          {{trackModal.customerNo}}/{{ trackModal.planNo }}
        </template>
      </CTrackInfoModal>

    </template>
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data="tableData.pageData"></CPager>
    </template>
  </Content>
</template>

<script>
import {
  defineComponent,
  onActivated,
  onMounted,
  reactive,
  toRefs,
  ref,
} from "vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import {
  Row,
  Col,
  Input,
  Space,
  Select,
  DatePicker,
  Button,
  Table,
  Dropdown,
  Menu,
  Spin,
  Modal,
  message,
} from "ant-design-vue";
import { currentTimeToUtc, gToKg, cmCubicToM, debounce, dateString } from "@/utils/general";
import { useI18n } from "vue-i18n/index";
import { getGlobalCountrys, searchCities } from "@/api/modules/common";
import { getSourceCountrys } from "@/api/modules/printWaybill/create";
import {
  getPagedList,
  printWaybillPlan,
  updateWaybillOperationStatus,
  exportWayBill,
} from "@/api/modules/printWaybill/list";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  destinationType as destinationTypeEnum,
  waybillOperationStatus as waybillOperationStatusEnum,
} from "@/enum/enum.json";
import localPrint from "@/utils/localPrint";
import { downloadFile } from "@/utils/downloader"
import { getWaybillTrackList } from '@/api/modules/printWaybill/list';


export default defineComponent({
  name: "print_waybill_list",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASpace: Space,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASpin: Spin,
    AInputGroup: Input.Group,
    Content,
    CPager,
    CTrackInfoModal
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const router = useRouter();

    const state = reactive({
      // 目的地类型
      destinationTypeList: [],
      // 始发国家
      fromCountryList: [],
      // 目的国家/城市
      toCountryList: [],
      toCityListLoading: false,
      toCityList: [],
      // 操作状态
      waybillOperationStatusList: [],
      searchState: {
        searchKey: null,
        destinationType: null,
        fromCountryId: null,
        toCountryId: null,
        toCityId: null,
        waybillOperationStatus: null,
        beginCreationTime: null,
        endCreationTime: null,
      },
      searchStateCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    state.destinationTypeList = Object.values(destinationTypeEnum);
    state.waybillOperationStatusList = Object.values(
      waybillOperationStatusEnum
    );

    const columns = [
      {
        width: 150,
        fixed: "left",
        slots: {
          title: "orderNo",
          customRender: "orderNoCustom",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.shipping_lines"),
        slots: {
          customRender: "transportRoutes",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.destination_type"),
        slots: {
          customRender: "destinationType",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.originating_country"),
        slots: {
          customRender: "from",
        },
      },
      {
        width: 120,
        // title: () => vueI18n.t("warehouse.purpose_country_city"),
        slots: {
          title: "toTitle",
          customRender: "to",
        },
      },
      {
        dataIndex: "totalBoxCount",
        width: 70,
        title: () => vueI18n.t("warehouse.number"),
      },
      {
        width: 100,
        slots: {
          title: "weightTitle",
          customRender: "totalWeight",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.volume"),
        slots: {
          customRender: "totalVolume",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.total_cost"),
        slots: {
          customRender: "totalPrice",
        },
      },
      {
        width: 120,
        slots: {
          title: "channelTitle",
          customRender: "supplierLogistic",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.tracking_number_1"),
        slots: {
          customRender: "transferOrderNo",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.operating_state"),
        slots: {
          customRender: "waybillOperationStatus",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.turn_single_track"),
        slots: {
          customRender: "track",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.creation_time"),
        slots: {
          customRender: "creationTime",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("common.operation"),
        fixed: "right",
        slots: {
          customRender: "operation",
        },
      },
    ];

    const getLanguageName = (cnName, enName) => {
      if (getters.language === "zh-CN") {
        return cnName;
      }
      return enName;
    };

    const handleGToKg = (val) => {
      if (!val) {
        return 0;
      }
      return gToKg(val);
    };

    const handleCmCubicToM = (val) => {
      if (!val) {
        return 0;
      }
      return cmCubicToM(val);
    };

    const handleDateChange = (e) => {
      let beginTime = null,
        endTime = null;
      if (e.length === 2) {
        beginTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchState.beginCreationTime = beginTime;
      state.searchState.endCreationTime = endTime;
    };

    const getPageData = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.searchStateCache,
        state.tableData.pageData
      );
      getPagedList(data)
        .then(({ result }) => {
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitSearch();
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    const handleToCountryChange = () => {
      state.toCityList = [];
      state.searchState.toCityId = null;
    };

    const getCityList = debounce((searchKey) => {
      if (searchKey) {
        let countryId = state.searchState.toCountryId;
        state.toCityList = [];
        if (countryId) {
          state.toCityListLoading = true;
          const data = {
            countryId,
            searchKey,
            skipCount: 0,
            maxResultCount: 10,
          };
          searchCities(data)
            .then(({ result }) => {
              if (result) {
                let { items = [] } = result;
                state.toCityList = items;
              }
            })
            .finally(() => {
              state.toCityListLoading = false;
            });
        }
      }
    }, 500);

    const handlePrint = (id) => {
      printWaybillPlan({ id }).then(({ result }) => {
        if (result) {
          localPrint(result);
        }
      });
    };

    const handleCancel = (id) => {
      Modal.confirm({
        title: vueI18n.t("warehouse.cancel_waybill"),
        content: vueI18n.t("warehouse.confirm_cancellation_of_waybill"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          const data = {
            id,
            waybillOperationStatus: waybillOperationStatusEnum.cancelled,
          };
          return updateWaybillOperationStatus(data).then(() => {
            message.success(vueI18n.t("common.successfully_canceled"));
            handleInitSearch();
          });
        },
      });
    };

    const handleNavigate = (record) => {
      // 第一步 表示已完成第一步
      // 第二步 表示已付完款
      if (record.planStatus === 1
        &&
        !(record.waybillOperationStatus == waybillOperationStatusEnum.cancelled)
        &&
        !(record.waybillOperationStatus == waybillOperationStatusEnum.managerCancelled)) {
        router.push({ name: 'print_waybill_create', params: { id: record.id } })
      } else {
        router.push({ name: 'print_waybill_details', params: { id: record.id } })
      }
    }

    const handleMenuClick = ({ key, item }) => {
      let { record } = item;
      switch (key) {
        case 1:
          handlePrint(record.id);
          break;
        case 2:
          handleCancel(record.id);
          break;
        case 3:
          handleShowTrackModal(record);
          break;
        case 4:
          funcCopy(record.id);
          break;
        default:
          break;
      }
    };

    const trackModal = reactive({
      visible: false,
      loading: false,
      customerNo: null,
      planNo: null,
      list: [],
      id: null,
    });

    const handleShowTrackModal = (record) => {
      trackModal.visible = true;
      trackModal.customerNo = record.customerNo;
      trackModal.planNo = record.planNo;
      trackModal.planId = record.id;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getWaybillTrackList({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    const funcCopy = (id) => {
      router.push({ name: 'print_waybill_create', query: { fromId: id } })
    }

    const handleDownloadExcel = () => {
      state.tableData.loading = true
      let url = exportWayBill();
      const data = Object.assign({}, state.searchStateCache)
      downloadFile(url, `print_waybill_${dateString()}.csv`, "POST", data).then(() => {
        state.tableData.visible = false
      }).catch(() => { }).finally(() => {
        state.tableData.loading = false
      })
    }

    onMounted(async () => {
      try {
        let { result: fromCountryList } = await getSourceCountrys();
        if (fromCountryList) {
          state.fromCountryList = fromCountryList;
        }

        let { result: toCountryList } = await getGlobalCountrys({
          isActive: true,
        });
        if (toCountryList) {
          state.toCountryList = toCountryList;
        }
      } catch (error) { }
    });

    onActivated(handleInitSearch);

    return {
      ...toRefs(state),
      columns,
      waybillOperationStatusEnum,
      handlePage,
      handleDateChange,
      handleSearch,
      getLanguageName,
      handleGToKg,
      handleCmCubicToM,
      handleToCountryChange,
      getCityList,
      handleNavigate,
      handleMenuClick,
      handleDownloadExcel,
      trackModal
    };
  },
});
</script>
